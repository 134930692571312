import * as React from 'react';
import {
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
} from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import {
  RaRecord,
  TextField,
  useCreatePath,
  useListContext,
} from 'react-admin';
import { Link } from 'react-router-dom';
import SelectChipField from '../common/SelectChipField';
import { ImageListProps } from '../common/CustomImageList';
import boardGameStatusChoices from '../../../choices/board-game/status.choices';
import ExtensionIcon from '@mui/icons-material/Extension';
import Chip from '../common/Chip';

interface SubtitleProps {
  record: RaRecord;
}

const ItemSubtitle: React.FC<SubtitleProps> = ({ record }) => {
  return <TextField record={record} source="nameFr" />;
};

const PostsImageList: React.FC<ImageListProps> = ({ rowHeight, cols, sx }) => {
  const { data } = useListContext();
  const createPath = useCreatePath();

  const resource = 'board-games';

  return data ? (
    <ImageList rowHeight={rowHeight} cols={cols} sx={sx}>
      {data.length > 0 &&
        data.map((record: RaRecord) => (
          <ImageListItem
            component={Link}
            key={record.id}
            to={createPath({
              resource,
              id: record.id,
              type: 'edit',
            })}
            sx={{ overflow: 'hidden' }}
          >
            <Box
              component="img"
              sx={{
                height: '100%',
                objectFit: 'cover',
              }}
              src={record.smallBox}
              alt={'box'}
            />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                background:
                  'linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)',
                '&:hover .subtitle': {
                  opacity: 1,
                },
              }}
            >
              <Typography
                className="subtitle"
                sx={{
                  opacity: 0,
                  transition: 'opacity ease-in-out 0.5s',
                  fontSize: '6rem',
                  color: '#fff',
                }}
              >
                {record.overall}
              </Typography>
            </Box>
            <Box sx={{ position: 'absolute', top: '0.1rem', right: '0.1rem' }}>
              <SelectChipField
                record={record}
                source="status"
                choices={boardGameStatusChoices}
              />
            </Box>
            {!!record.baseGame && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '0.1rem',
                  left: '0.1rem',
                }}
              >
                <Chip sx={{ backgroundColor: deepPurple[500] }}>
                  <ExtensionIcon fontSize="small" />
                </Chip>
              </Box>
            )}
            <ImageListItemBar
              sx={{
                height: 'auto',
                paddingBottom: '0.2rem',
                background: 'transparent',
              }}
              title={record.title}
              subtitle={<ItemSubtitle record={record} />}
            />
          </ImageListItem>
        ))}
    </ImageList>
  ) : null;
};

export default PostsImageList;
