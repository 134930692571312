import * as React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import { useTranslate } from 'react-admin';

interface ChartCardProps {
  label: string;
  labelSuffix?: string;
  children?: React.ReactNode;
}

const ChartCard: React.FC<ChartCardProps> = (props) => {
  const { label, labelSuffix, children } = props;
  const translate = useTranslate();

  let title = translate(label);
  if (labelSuffix) {
    title += translate(labelSuffix);
  }

  return (
    <Card sx={{ mb: 2 }}>
      <CardHeader title={title} />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default ChartCard;
