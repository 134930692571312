import { Language } from '../../utils/enum';

const boardGameLanguageChoices = [
  {
    id: Language.FRENCH,
    name: 'resource.board-games.field.language.french',
  },
  {
    id: Language.ENGLISH,
    name: 'resource.board-games.field.language.english',
  },
];

export default boardGameLanguageChoices;
