import * as React from 'react';
import {
  AutocompleteInput,
  List,
  Pagination,
  ReferenceInput,
  SelectInput,
  TextInput,
  useTranslate,
} from 'react-admin';
import CustomImageList from '../../components/common/CustomImageList';
import BoardGamesImageList from '../../components/board-games/BoardGamesImageList';
import boardGameStatusChoices from '../../../choices/board-game/status.choices';
import booleanChoices from '../../../choices/common/boolean.choices';

const filters = [
  <TextInput
    source="q"
    label="ra.action.search"
    resettable
    alwaysOn
    sx={{ mb: 0.5 }}
  />,
  <SelectInput
    source="status"
    label="resource.board-games.field.status.name"
    choices={boardGameStatusChoices}
    alwaysOn
  />,
  <SelectInput
    source="isExpansion"
    label="resource.board-games.field.isExpansion"
    choices={booleanChoices}
    alwaysOn
  />,
  <ReferenceInput
    reference="tags"
    source="tagId"
    label="resource.board-games.field.tags"
  >
    <AutocompleteInput optionText="nameFr" />
  </ReferenceInput>,
];

const CriticsList = () => {
  const translate = useTranslate();

  return (
    <List
      title={translate(`resource.board-games.name`, {
        smart_count: 2,
      })}
      filters={filters}
      sort={{ field: 'overall', order: 'DESC' }}
      perPage={12}
      pagination={<Pagination rowsPerPageOptions={[6, 12, 24]} />}
    >
      <CustomImageList
        loadedImageList={<BoardGamesImageList />}
        rowHeight={320}
      />
    </List>
  );
};

export default CriticsList;
