export enum ThemeName {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum BoardGameStatus {
  WISHLIST = 'WISHLIST',
  PREORDERED = 'PREORDERED',
  OWNED = 'OWNED',
  PLAYED = 'PLAYED',
}

export enum Language {
  FRENCH = 'FRENCH',
  ENGLISH = 'ENGLISH',
}
