import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useTranslate } from 'react-admin';
import customDataProvider from '../../../providers/data-provider';
import { COLORS, getOptions, height } from '../../../utils/chart';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

interface Props {
  type?: string;
}

const BoardGamesRatesCountChart: React.FC<Props> = ({ type = 'overall' }) => {
  const theme = useTheme();
  const translate = useTranslate();
  const [boardGamesRatesCount, setBoardGamesRatesCount] = React.useState<
    { rate: number; total: number }[]
  >([]);

  React.useEffect(() => {
    customDataProvider
      .request(`/board-games/count/rates?type=${type}`)
      .then((response) => setBoardGamesRatesCount(response));
  }, [type]);

  const randomColorIndex = Math.floor(Math.random() * COLORS.length);

  const data = {
    labels: boardGamesRatesCount.map((item) => item.rate),
    datasets: [
      {
        label: translate('screen.dashboard.charts.boardGamesRatesTypes.games'),
        data: boardGamesRatesCount.map((item) => item.total),
        backgroundColor: COLORS[randomColorIndex],
        borderColor: COLORS[randomColorIndex],
      },
    ],
  };

  const options = getOptions(theme, true);

  return <Line height={height} data={data} options={options} />;
};

export default BoardGamesRatesCountChart;
