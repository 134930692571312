import { Theme } from '@mui/material';
import { LayoutPosition } from 'chart.js';

export const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A400FE'];

export const height = 250;

export const getOptions = (
  theme: Theme,
  lineOptions = false,
  position: LayoutPosition = 'bottom',
) => ({
  tooltips: {
    displayColors: false,
    bodyFontSize: 16,
    xPadding: 10,
    yPadding: 8,
  },
  maintainAspectRatio: false,
  scales: lineOptions ? { y: { min: 0 } } : undefined,
  plugins: {
    legend: {
      display: true,
      position,
      labels: {
        boxWidth: 20,
        font: {
          size: 18,
        },
        color: theme.palette.text.primary,
        padding: 20,
      },
    },
  },
});
