import * as React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  Edit,
  FormTab,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
  useRecordContext,
} from 'react-admin';
import boardGameStatusChoices from '../../../choices/board-game/status.choices';
import boardGameLanguageChoices from '../../../choices/board-game/language.choices';
import CustomRichTextInput from '../../components/common/CustomRichTextInput';
import S3ImageInput from '../../components/common/S3ImageInput';
import TagsReferenceArrayInput from '../../components/tags/TagsReferenceArrayInput';
import { Grid } from '@mui/material';

const BoardGameTitle = () => {
  const record = useRecordContext<BoardGameType>();
  return <span>{record ? record.nameFr : ''}</span>;
};

const BoardGameEdit = () => (
  <Edit title={<BoardGameTitle />} redirect={false}>
    <TabbedForm>
      <FormTab
        label="resource.board-games.tab.general"
        sx={{ maxWidth: '20em' }}
      >
        <TextInput
          disabled
          source="id"
          label="resource.common.field.id"
          fullWidth
        />
        <TextInput
          disabled
          source="slug"
          label="resource.common.field.slug"
          fullWidth
        />
        <TextInput
          source="nameFr"
          label="resource.board-games.field.nameFr"
          fullWidth
          validate={required()}
        />
        <TextInput
          source="nameEn"
          label="resource.board-games.field.nameEn"
          fullWidth
          validate={required()}
        />
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="officialNumberOfPlayers"
              label="resource.board-games.field.officialNumberOfPlayers"
              fullWidth
              validate={required()}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="myNumberOfPlayers"
              label="resource.board-games.field.myNumberOfPlayers"
              fullWidth
              validate={required()}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInput
              source="officialPlayingTime"
              label="resource.board-games.field.officialPlayingTime"
              min={5}
              step={5}
              fullWidth
              validate={required()}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInput
              source="myPlayingTime"
              label="resource.board-games.field.myPlayingTime"
              min={5}
              step={5}
              fullWidth
              validate={required()}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInput
              source="officialMinimumAge"
              label="resource.board-games.field.officialMinimumAge"
              min={3}
              step={1}
              fullWidth
              validate={required()}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInput
              source="myMinimumAge"
              label="resource.board-games.field.myMinimumAge"
              min={3}
              step={1}
              fullWidth
              validate={required()}
            />
          </Grid>
        </Grid>
        <NumberInput
          source="complexity"
          label="resource.board-games.field.complexity"
          min={1}
          max={100}
          step={1}
          fullWidth
          validate={required()}
        />
      </FormTab>
      <FormTab
        label="resource.board-games.tab.ratings"
        sx={{ maxWidth: '20em' }}
      >
        <NumberInput
          source="overall"
          label="resource.board-games.field.overall"
          min={1}
          max={100}
          step={1}
          fullWidth
          validate={required()}
        />
        <NumberInput
          source="design"
          label="resource.board-games.field.design"
          min={1}
          max={100}
          step={1}
          fullWidth
          validate={required()}
        />
        <NumberInput
          source="setup"
          label="resource.board-games.field.setup"
          min={1}
          max={100}
          step={1}
          fullWidth
          validate={required()}
        />
        <NumberInput
          source="luck"
          label="resource.board-games.field.luck"
          min={0}
          max={20}
          step={1}
          fullWidth
          validate={required()}
        />
        <NumberInput
          source="reflection"
          label="resource.board-games.field.reflection"
          min={0}
          max={20}
          step={1}
          fullWidth
          validate={required()}
        />
        <NumberInput
          source="replayability"
          label="resource.board-games.field.replayability"
          min={0}
          max={20}
          step={1}
          fullWidth
          validate={required()}
        />
        <NumberInput
          source="fun"
          label="resource.board-games.field.fun"
          min={0}
          max={20}
          step={1}
          fullWidth
          validate={required()}
        />
        <NumberInput
          source="interactivity"
          label="resource.board-games.field.interactivity"
          min={0}
          max={20}
          step={1}
          fullWidth
          validate={required()}
        />
      </FormTab>
      <FormTab label="resource.board-games.tab.images">
        <S3ImageInput
          source="box"
          label="resource.board-games.field.box"
          isRequired
          validate={required()}
        />
        <S3ImageInput
          source="smallBox"
          label="resource.board-games.field.smallBox"
          isRequired
          validate={required()}
        />
        <S3ImageInput
          source="thumbnail"
          label="resource.board-games.field.thumbnail"
          isRequired
          validate={required()}
        />
        <S3ImageInput
          source="pictures"
          label="resource.board-games.field.pictures"
          multiple
        />
      </FormTab>
      <FormTab label="resource.board-games.tab.texts">
        <CustomRichTextInput
          source="descriptionFr"
          label="resource.board-games.field.descriptionFr"
        />
        <CustomRichTextInput
          source="descriptionEn"
          label="resource.board-games.field.descriptionEn"
        />
        <CustomRichTextInput
          source="opinionFr"
          label="resource.board-games.field.opinionFr"
        />
        <CustomRichTextInput
          source="opinionEn"
          label="resource.board-games.field.opinionEn"
        />
      </FormTab>
      <FormTab label="resource.board-games.tab.tags">
        <TagsReferenceArrayInput degree="primary" />
        <TagsReferenceArrayInput degree="secondary" />
        <TagsReferenceArrayInput degree="tertiary" />
      </FormTab>
      <FormTab label="resource.board-games.tab.misc" sx={{ maxWidth: '20em' }}>
        <SelectInput
          source="status"
          label="resource.board-games.field.status.name"
          choices={boardGameStatusChoices}
          fullWidth
          isRequired
          validate={required()}
        />
        <SelectInput
          source="language"
          label="resource.board-games.field.language.name"
          choices={boardGameLanguageChoices}
          fullWidth
          isRequired
          validate={required()}
        />
        <BooleanInput
          source="pledged"
          label="resource.board-games.field.pledged"
        />
        <ReferenceInput
          source="baseGame.id"
          reference="board-games"
          sort={{ field: 'nameFr', order: 'ASC' }}
        >
          <AutocompleteInput
            label="resource.board-games.field.baseGame"
            optionText="nameFr"
            fullWidth
          />
        </ReferenceInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default BoardGameEdit;
