import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import { useTranslate } from 'react-admin';
import customDataProvider from '../../../providers/data-provider';
import { COLORS, getOptions, height } from '../../../utils/chart';

ChartJS.register(ArcElement, Title, Tooltip, Legend);

const BoardGamesCountChart: React.FC = () => {
  const theme = useTheme();
  const translate = useTranslate();
  const [boardGamesCount, setBoardGamesCount] = React.useState<any>({});

  React.useEffect(() => {
    customDataProvider.request('/board-games/count').then((response) => {
      // Excludes total
      const { baseGames, expansions } = response;
      setBoardGamesCount({ baseGames, expansions });
    });
  }, []);

  const data = {
    labels: Object.keys(boardGamesCount).map((key) =>
      translate(`screen.dashboard.charts.boardGamesTypes.${key}`),
    ),
    datasets: [
      {
        data: Object.values(boardGamesCount),
        backgroundColor: COLORS,
        borderColor: theme.palette.background.paper,
      },
    ],
  };

  const options = getOptions(theme);

  return <Doughnut height={height} data={data} options={options} />;
};

export default BoardGamesCountChart;
