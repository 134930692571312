import * as React from 'react';
import {
  Datagrid,
  Pagination,
  ReferenceManyField,
  ReferenceManyFieldProps,
  SimpleList,
  TextField,
  useTranslate,
} from 'react-admin';
import RefField from '../common/RefField';
import FormLabel from '../common/FormLabel';
import FormControl from '../common/FormControl';

enum TYPE {
  SIMPLE_LIST,
  DATA_GRID,
}

interface Props
  extends Omit<ReferenceManyFieldProps, 'target' | 'children' | 'reference'> {
  degree: number;
  type?: TYPE;
  showPagination?: boolean;
}

const BoardGamesReferenceManyFields: React.FC<Props> = (props) => {
  const { type = TYPE.DATA_GRID, showPagination = true, ...rest } = props;
  const reference = 'board-games';
  const translate = useTranslate();

  return (
    <FormControl>
      <FormLabel>
        {translate(`resource.${reference}.name`, {
          smart_count: 2,
        })}
      </FormLabel>
      <ReferenceManyField
        reference={reference}
        target="tagId"
        pagination={showPagination ? <Pagination /> : undefined}
        sort={{ field: 'overall', order: 'DESC' }}
        fullWidth
        {...rest}
      >
        {type === TYPE.DATA_GRID ? (
          <Datagrid>
            <RefField source="nameFr" label="resource.global.field.nameFr" />
            <TextField
              source="overall"
              label="resource.board-games.field.overall"
            />
            <TextField
              source="design"
              label="resource.board-games.field.design"
            />
            <TextField
              source="setup"
              label="resource.board-games.field.setup"
            />
          </Datagrid>
        ) : (
          <SimpleList
            primaryText={(record) => record.nameFr}
            secondaryText={(record) => record.overall}
          />
        )}
      </ReferenceManyField>
    </FormControl>
  );
};

export default BoardGamesReferenceManyFields;
