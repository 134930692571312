import { AuthProvider } from 'react-admin';
import decodeJwt from 'jwt-decode';
import gravatar from 'gravatar';
import customDataProvider from './data-provider';

const authProvider: AuthProvider = {
  // Called when the user attempts to log in
  login: ({ email, password }) => {
    const request = new Request(
      `${process.env.REACT_APP_SERVER_HOST}/auth/signin`,
      {
        method: 'POST',
        body: JSON.stringify({ email, password }),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      },
    );
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ accessToken }) => {
        localStorage.setItem('accessToken', accessToken);
      });
  },
  // Called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem('accessToken');
    return Promise.resolve();
  },
  // Called when the data provider returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('accessToken');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // Called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      const { exp }: { exp: number } = decodeJwt(accessToken);
      if (exp * 1000 > new Date().getTime()) {
        return Promise.resolve();
      }
      return Promise.reject();
    }
    return Promise.reject();
  },
  // Called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      return Promise.resolve();
    }
    return Promise.reject();
  },
  // Called to display profile information like in the top right corner
  getIdentity: () => {
    return customDataProvider
      .getOne('users', { id: 'me' })
      .then(({ data: user }) => {
        user.fullName = `${user.firstName} ${user.lastName}`;
        user.avatar = gravatar.url(user.email, { d: 'mm' });
        return user;
      });
  },
};

export default authProvider;
