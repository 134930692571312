import * as React from 'react';
import {
  BooleanInput,
  DateTimeInput,
  Edit,
  SimpleForm,
  TextInput,
  useRecordContext,
} from 'react-admin';

const UserTitle = () => {
  const record = useRecordContext<UserType>();
  return <span>{record ? `${record.firstName} ${record.lastName}` : ''}</span>;
};

const UserEdit = () => (
  <Edit title={<UserTitle />}>
    <SimpleForm sx={{ maxWidth: '20em' }}>
      <TextInput
        disabled
        source="firstName"
        label="resource.users.field.firstName"
        fullWidth
      />
      <TextInput
        disabled
        source="lastName"
        label="resource.users.field.lastName"
        fullWidth
      />
      <TextInput
        disabled
        source="email"
        label="resource.users.field.email"
        fullWidth
      />
      <DateTimeInput
        disabled
        source="createdAt"
        label="resource.common.field.createdAt"
        fullWidth
      />
      <BooleanInput
        source="isActivated"
        label="resource.users.field.isActivated"
      />
    </SimpleForm>
  </Edit>
);

export default UserEdit;
