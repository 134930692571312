import BoardGameIcon from '@mui/icons-material/Casino';

import BoardGamesList from './BoardGamesList';
import BoardGameCreate from './BoardGameCreate';
import BoardGameEdit from './BoardGameEdit';

const BoardGame = {
  list: BoardGamesList,
  create: BoardGameCreate,
  edit: BoardGameEdit,
  icon: BoardGameIcon,
};

export default BoardGame;
