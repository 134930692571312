import { TranslationMessages } from 'react-admin';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
  ...englishMessages,
  ra: {
    ...englishMessages.ra,
    auth: {
      ...englishMessages.ra.auth,
      email: 'Email',
    },
  },
  month: {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  },
  component: {
    s3ImageInput: {
      action: {
        uploading: 'Uploading in progress...',
        filesNotSupported: '%{filesCount} file(s) not supported',
        uploadFileError: 'Error when trying to upload file',
        deleteFileError: 'Error when trying to delete old file',
        selectRequiredError: 'You must select at least 1 image',
      },
    },
    richText: {
      imageButtons: {
        label: 'Image',
        dialogTitle: 'Image URL',
      },
      colorButtons: {
        colorLabel: 'Color',
        highlightLabel: 'Highlight',
      },
    },
  },
  screen: {
    dashboard: {
      charts: {
        boardGamesTypes: {
          title: 'Types of Games',
          baseGames: 'Base games',
          expansions: 'Expansions',
        },
        boardGamesRatesTypes: {
          title: 'Games by rate: ',
          games: 'Games',
        },
      },
    },
    configurations: {
      name: 'Configuration',
      field: {
        language: 'Language',
        theme: {
          name: 'Theme',
          light: 'Light',
          dark: 'Dark',
        },
      },
    },
  },
  resource: {
    global: {
      field: {
        title: 'Title',
        subtitle: 'Subtitle',
        date: 'Date',
        name: 'Name',
        nameFr: 'Name 🇫🇷',
        nameEn: 'Name 🇬🇧',
        value: 'Value',
        thumbnail: 'Thumbnail',
      },
      action: {
        copy: 'Copy',
        textCopied: '%{textName} successfully copied into clipboard',
        copyError: 'Error when trying to copy %{textName} into clipboard',
      },
    },
    common: {
      field: {
        id: 'id',
        slug: 'Slug',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
      },
    },
    administration: {
      name: 'Administration',
    },
    profile: {
      name: 'Profile',
      action: {
        edit: {
          avatar: 'You can customize it using',
        },
        delete: {
          button: 'Delete my account',
          title: 'Are you sure you want to delete your account?',
          message: 'All related posts will also be deleted.',
        },
      },
    },
    users: {
      name: 'User |||| Users',
      field: {
        firstName: 'First name',
        lastName: 'Last name',
        fullName: 'Full name',
        email: 'Email',
        role: {
          name: 'Role',
          admin: 'Admin',
          publisher: 'Publisher',
          editor: 'Editor',
        },
        isActivated: 'Is activated',
        unactivated: 'Unactivated',
      },
      action: {
        activate: 'Activate',
        userActivated: 'User activated',
      },
    },
    'api-keys': {
      name: 'API key |||| API keys',
    },
    'board-games': {
      name: 'Board game |||| Board games',
      tab: {
        general: 'General',
        ratings: 'Ratings',
        images: 'Images',
        texts: 'Texts',
        tags: 'Tags',
        misc: 'Misc',
      },
      field: {
        descriptionFr: 'Description 🇫🇷',
        descriptionEn: 'Description 🇬🇧',
        opinionFr: 'Opinion 🇫🇷',
        opinionEn: 'Opinion 🇬🇧',
        officialNumberOfPlayers: 'Official number of players',
        myNumberOfPlayers: 'My number of players',
        officialPlayingTime: 'Official playing time',
        myPlayingTime: 'My playing time',
        officialMinimumAge: 'Official minimum age',
        myMinimumAge: 'My minimum age',
        complexity: 'Complexity',
        overall: 'Overall',
        design: 'Design',
        setup: 'Setup',
        luck: 'Luck',
        reflection: 'Reflection',
        replayability: 'Replayability',
        fun: 'Fun',
        interactivity: 'Interactivity',
        box: 'Box [max-width: 800px or max-height: 1000px]',
        smallBox: 'Box (small) [width: 350px]',
        thumbnail: 'Thumbnail [300x250]',
        pictures: 'Pictures [width: 800px]',
        status: {
          name: 'Status',
          wishlist: 'Wishlist',
          preordered: 'Preordered',
          owned: 'Owned',
          played: 'Played',
        },
        language: {
          name: 'Language',
          french: 'French',
          english: 'English',
        },
        pledged: 'Pledged',
        tags: 'Tags',
        primaryTags: 'Primary tags',
        secondaryTags: 'Secondary tags',
        tertiaryTags: 'Tertiary tags',
        baseGame: 'Base game',
        isExpansion: 'Expansion',
      },
    },
    tags: {
      name: 'Tag |||| Tags',
      field: {
        existing: 'Existing tags',
        hasRelations: 'Related game(s)',
      },
      action: {
        fetchExistingError: 'Error when trying to fetch existing tags',
      },
    },
  },
};

export default customEnglishMessages;
