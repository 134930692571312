import * as React from 'react';
import {
  Datagrid,
  DateField,
  EditButton,
  List,
  SimpleList,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin';
import CodeSnippetField from '../../components/common/CodeSnippetField';
import CopyButton from '../../components/common/CopyButton';
import { Theme, useMediaQuery } from '@mui/material';

const filters = [
  <TextInput
    source="q"
    label="ra.action.search"
    resettable
    alwaysOn
    sx={{ mb: 0.5 }}
  />,
];

const ApiKeysList = () => {
  const translate = useTranslate();
  const isSmall = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('sm'),
    { noSsr: true },
  );
  const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), {
    noSsr: true,
  });

  return (
    <List
      title={translate(`resource.api-keys.name`, {
        smart_count: 2,
      })}
      filters={filters}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.name}
          secondaryText={(record) => record.value}
          tertiaryText={(record) =>
            new Date(record.updatedAt).toLocaleDateString()
          }
        />
      ) : (
        <Datagrid>
          <TextField source="name" label="resource.global.field.name" />
          <CodeSnippetField
            source="value"
            label="resource.global.field.value"
          />
          <DateField
            source="createdAt"
            label="resource.common.field.createdAt"
            locales="fr-FR"
            showTime
          />
          <DateField
            source="updatedAt"
            label="resource.common.field.updatedAt"
            locales="fr-FR"
            showTime
          />
          <EditButton />
          {isLarge && (
            <CopyButton source="value" textName="resource.api-keys.name" />
          )}
        </Datagrid>
      )}
    </List>
  );
};

export default ApiKeysList;
