import * as React from 'react';
import { useCreatePath, useTranslate } from 'react-admin';
import customDataProvider from '../../../providers/data-provider';
import Chip from '../../components/common/Chip';
import { Box, CircularProgress, Typography } from '@mui/material';
import { grey, red } from '@mui/material/colors';
import FormControl from '../../components/common/FormControl';
import FormLabel from '../../components/common/FormLabel';
import { Link } from 'react-router-dom';

interface Props {
  createdTags?: number;
}

const ExistingTags: React.FC<Props> = ({ createdTags }: Props) => {
  const translate = useTranslate();
  const createPath = useCreatePath();
  const [existingTags, setExistingTags] = React.useState<TagType[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();

  React.useEffect(() => {
    customDataProvider
      .getAll<TagType>('tags')
      .then((data) => {
        setExistingTags(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => setLoading(false));
  }, [createdTags]);

  return (
    <FormControl>
      <FormLabel>{translate('resource.tags.field.existing')}</FormLabel>
      <Box
        sx={{
          width: '100%',
          backgroundColor: grey[900],
          padding: '0.5rem 0.75rem',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {error ? (
          <Typography sx={{ color: red[500] }}>
            {translate('resource.tags.action.fetchExistingError')}
          </Typography>
        ) : loading ? (
          <CircularProgress sx={{ margin: '0 auto' }} />
        ) : (
          existingTags?.map((existingTag) => (
            <Link
              key={existingTag.nameFr}
              to={createPath({
                resource: 'tags',
                type: 'edit',
                id: existingTag.id,
              })}
              style={{ textDecoration: 'none' }}
            >
              <Chip sx={{ margin: 1 }}>{existingTag.nameFr}</Chip>
            </Link>
          ))
        )}
      </Box>
    </FormControl>
  );
};

export default ExistingTags;
