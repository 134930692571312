import * as React from 'react';
import {
  Datagrid,
  EditButton,
  List,
  SimpleList,
  TextField,
  TextInput,
  useTranslate,
  SelectInput,
} from 'react-admin';
import { Theme, useMediaQuery } from '@mui/material';
import booleanChoices from '../../../choices/common/boolean.choices';
import DeleteTagButton from '../../components/tags/DeleteTagButton';

const filters = [
  <TextInput
    source="q"
    label="ra.action.search"
    resettable
    alwaysOn
    sx={{ mb: 0.5 }}
  />,
  <SelectInput
    source="hasRelations"
    label="resource.tags.field.hasRelations"
    choices={booleanChoices}
    alwaysOn
  />,
];

const TagsList = () => {
  const translate = useTranslate();
  const isSmall = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('sm'),
    { noSsr: true },
  );
  const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), {
    noSsr: true,
  });

  return (
    <List
      title={translate(`resource.tags.name`, {
        smart_count: 2,
      })}
      filters={filters}
      sort={{ field: 'totalCount', order: 'DESC' }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.name}
          secondaryText={(record) => record.postsCount}
          tertiaryText={(record) =>
            new Date(record.updatedAt).toLocaleDateString()
          }
        />
      ) : (
        <Datagrid>
          <TextField source="nameFr" label="resource.global.field.name" />
          <TextField
            source="totalCount"
            label={translate('resource.board-games.field.tags', {
              smart_count: 2,
            })}
          />
          <TextField
            source="primaryCount"
            label={translate('resource.board-games.field.primaryTags', {
              smart_count: 2,
            })}
          />
          <TextField
            source="secondaryCount"
            label={translate('resource.board-games.field.secondaryTags', {
              smart_count: 2,
            })}
          />
          <TextField
            source="tertiaryCount"
            label={translate('resource.board-games.field.tertiaryTags', {
              smart_count: 2,
            })}
          />
          <EditButton />
          {isLarge && <DeleteTagButton />}
        </Datagrid>
      )}
    </List>
  );
};

export default TagsList;
