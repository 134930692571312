import React, { Children, ReactElement } from 'react';
import { styled } from '@mui/material/styles';
import FormHelperText from '@mui/material/FormHelperText';
import {
  FileInputClasses,
  ImageInputProps,
  InputHelperText,
  Labeled,
  minLength,
  required,
  sanitizeInputRestProps,
  shallowEqual,
  useInput,
  useNotify,
  useRecordContext,
  useResourceContext,
  useTranslate,
  Validator,
} from 'react-admin';
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { RemoveCircle } from '@mui/icons-material';
import customDataProvider from '../../../providers/data-provider';
import { useDropzone } from 'react-dropzone';
import get from 'lodash/get';

// Extends ImageInputProps with a select prop (to select a primary image when multiple)
// select refers to the source of this primary image URL (eg. gallery cover)
interface S3ImageInputProps extends ImageInputProps {
  select?: string;
}

// Custom React-Admin component based on ImageInput,
// except it uploads files to S3 and use public S3 URL as values.
// Files maximum size is automatically lower or equals to 5MB (aligned with server conf)
const S3ImageInput = (props: S3ImageInputProps) => {
  const {
    accept,
    children,
    className,
    format,
    helperText,
    inputProps: inputPropsOptions,
    maxSize,
    minSize,
    multiple = false,
    label,
    labelMultiple = 'ra.input.file.upload_several',
    labelSingle = 'ra.input.file.upload_single',
    // options = {},
    onRemove: onRemoveProp,
    parse,
    placeholder,
    // resource,
    source,
    validate,
    validateFileRemoval,
    select,
    ...rest
  } = props;
  // const { onDrop: onDropProp } = options;
  const translate = useTranslate();

  // turn a browser dropped file structure into expected structure
  const transformFile = (file: any) => {
    if (!(file instanceof File)) {
      return file;
    }

    const { source, title } = (Children.only(children) as ReactElement<any>)
      .props;

    const preview = URL.createObjectURL(file);
    const transformedFile = {
      rawFile: file,
      [source]: preview,
    };

    if (title) {
      transformedFile[title] = file.name;
    }

    return transformedFile;
  };

  const transformFiles = (files: any[]) => {
    if (!files) {
      return multiple ? [] : null;
    }

    if (Array.isArray(files)) {
      return files.map(transformFile);
    }

    return transformFile(files);
  };

  // Custom: auto validate (as only required function is not enough)
  const inputValidate: Validator | Validator[] | undefined =
    validate === required()
      ? [required(), minLength(1, 'ra.validation.minValue')]
      : validate;

  const {
    id,
    field: { onChange, value },
    fieldState,
    formState: { isSubmitted },
    isRequired,
  } = useInput({
    format: format || transformFiles,
    parse: parse || transformFiles,
    source,
    // validate,
    validate: inputValidate,
    ...rest,
  });
  const { isTouched, error } = fieldState;
  // const files = value ? (Array.isArray(value) ? value : [value]) : [];
  const imagesUrls = value ? (Array.isArray(value) ? value : [value]) : [];

  // Custom
  const theme = useTheme();
  const record = useRecordContext(props);
  const resource = useResourceContext(props);
  const notify = useNotify();
  const initialImagesUrls = get(record, source) || [];

  // Custom: deletes temporary image (image that is not stored in database yet)
  const deleteTemporaryImage = (imageUrl: string): Promise<void> => {
    return imageUrl && !initialImagesUrls.includes(imageUrl)
      ? customDataProvider.deleteS3Object(imageUrl).catch(() => {
          notify('component.s3ImageInput.action.deleteFileError', {
            type: 'error',
          });
        })
      : Promise.resolve();
  };

  // Custom: loading state (used by loader)
  const [uploading, setUploading] = React.useState(false);

  // const onDrop = (newFiles, rejectedFiles, event) => {
  //   const updatedFiles = multiple ? [...files, ...newFiles] : [...newFiles];

  //   if (multiple) {
  //     onChange(updatedFiles);
  //   } else {
  //     onChange(updatedFiles[0]);
  //   }

  //   if (onDropProp) {
  //     onDropProp(newFiles, rejectedFiles, event);
  //   }
  // };

  // Custom
  const onDrop = async (
    acceptedFiles: File[],
    rejectedFiles: any[],
    event: any,
  ) => {
    if (rejectedFiles.length > 0) {
      notify(
        translate('component.s3ImageInput.action.filesNotSupported', {
          filesCount: rejectedFiles.length,
        }),
        { type: 'warning' },
      );
    }

    setUploading(true);
    const promises = acceptedFiles.map((file) =>
      customDataProvider.uploadFileToS3(file, source),
    );

    Promise.all(promises)
      .then((responses) => {
        const s3PublicImagesUrls = responses.map(
          (response) => response.Location,
        );
        const updatedImages = multiple
          ? [...imagesUrls, ...s3PublicImagesUrls]
          : [...s3PublicImagesUrls];
        if (multiple) {
          onChange(updatedImages);
        } else {
          // Deletes old image if not stored in database
          deleteTemporaryImage(imagesUrls[0]).finally(() =>
            onChange(updatedImages[0]),
          );
        }
      })
      .catch(() => {
        notify('component.s3ImageInput.action.uploadFileError', {
          type: 'error',
        });
      })
      .finally(() => setUploading(false));
  };

  // const onRemove = (file) => async () => {
  //   if (validateFileRemoval) {
  //     try {
  //       await validateFileRemoval(file);
  //     } catch (e) {
  //       return;
  //     }
  //   }
  //   if (multiple) {
  //     const filteredFiles = files.filter(
  //       (stateFile) => !shallowEqual(stateFile, file),
  //     );
  //     onChange(filteredFiles as any);
  //   } else {
  //     onChange(null);
  //   }

  //   if (onRemoveProp) {
  //     onRemoveProp(file);
  //   }
  // };

  // Custom
  const onRemove = (imageUrl: any) => () => {
    deleteTemporaryImage(imageUrl);

    // Unselects removed image if needed
    if (selectValue === imageUrl) {
      selectOnChange(null);
    }

    if (multiple) {
      const filteredFiles = imagesUrls.filter(
        (url) => !shallowEqual(url, imageUrl),
      );
      onChange(filteredFiles as any);
    } else {
      onChange(null);
    }
  };

  // const childrenElement =
  //   children && isValidElement(Children.only(children))
  //     ? (Children.only(children) as ReactElement<any>)
  //     : undefined;

  // const { getRootProps, getInputProps } = useDropzone({
  //   accept,
  //   maxSize,
  //   minSize,
  //   multiple,
  //   ...options,
  //   onDrop,
  // });

  // Custom: limit max size to 5MB
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    maxSize: maxSize ? Math.min(maxSize, 5_000_000) : 5_000_000, // Max is 5MB
    minSize,
    multiple,
    onDrop,
  });

  // Custom: select prop related code part
  const {
    id: selectId,
    field: { onChange: selectOnChange, value: selectValue },
    fieldState: selectFieldState,
    formState: { isSubmitted: isSelectSubmitted },
  } = useInput({
    source: select || '', // Required (and not undefined)
    validate: select
      ? required('component.s3ImageInput.action.selectRequiredError')
      : undefined,
    ...rest,
  });
  const { isTouched: IsSelectTouched = false, error: selectError } =
    selectFieldState;
  const selectImage = (imageUrl: string) => {
    selectOnChange(imageUrl);
  };

  return (
    <StyledLabeled
      htmlFor={id}
      label={label}
      source={source}
      resource={resource}
      isRequired={isRequired}
      sx={{ width: '100%' }}
      {...sanitizeInputRestProps(rest)}
    >
      <>
        <div
          {...getRootProps({
            className: FileInputClasses.dropZone,
            'data-testid': 'dropzone',
          })}
        >
          <input
            id={id}
            name={id}
            {...getInputProps({
              ...inputPropsOptions,
            })}
          />
          {/* Custom: input for select prop */}
          {select && (
            <input
              id={selectId}
              name={selectId}
              {...getInputProps({
                ...inputPropsOptions,
              })}
            />
          )}
          {placeholder ? (
            placeholder
          ) : multiple ? (
            <p>{translate(labelMultiple)}</p>
          ) : (
            <p>{translate(labelSingle)}</p>
          )}
        </div>
        <FormHelperText>
          <InputHelperText
            touched={isTouched || isSubmitted}
            error={error?.message}
            helperText={helperText}
          />
        </FormHelperText>
        {/* Custom: loader indicator */}
        {uploading && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              '& p': {
                marginLeft: '1rem',
              },
            }}
          >
            <CircularProgress size={20} />
            <Typography>
              {translate('component.s3ImageInput.action.uploading')}
            </Typography>
          </Box>
        )}
        {/* {children && (
          <div className="previews">
            {files.map((file, index) => (
              <FileInputPreview
                key={index}
                file={file}
                onRemove={onRemove(file)}
                className={FileInputClasses.removeButton}
              >
                {cloneElement(childrenElement as ReactElement, {
                  record: file,
                  className: FileInputClasses.preview,
                })}
              </FileInputPreview>
            ))}
          </div>
        )} */}
        {/* Custom: previews */}
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {imagesUrls.map((file, index) => (
            <Box
              key={file}
              sx={{
                display: 'inline-block',
                position: 'relative',
                '& button': {
                  position: 'absolute',
                  top: theme.spacing(1),
                  right: theme.spacing(1),
                  minWidth: theme.spacing(2),
                  opacity: 0,
                },
                '&:hover button': {
                  opacity: 1,
                },
              }}
            >
              <IconButton
                onClick={onRemove(file)}
                aria-label={translate('ra.action.delete')}
                title={translate('ra.action.delete')}
              >
                <RemoveCircle sx={{ color: theme.palette.error.main }} />
              </IconButton>
              <Box
                component="img"
                sx={{
                  margin: '0.5rem',
                  maxHeight: '10rem',
                  cursor: select ? 'pointer' : 'default',
                  boxSizing: selectValue === file ? 'border-box' : 'none',
                  borderWidth: selectValue === file ? '0.25rem' : 0,
                  borderStyle: selectValue === file ? 'solid' : 'none',
                  borderColor: theme.palette.primary.main,
                }}
                src={file}
                onClick={() => (select ? selectImage(file) : undefined)}
                alt={`file-${index}`}
              />
            </Box>
          ))}
        </Box>
        <FormHelperText>
          <InputHelperText
            touched={IsSelectTouched || isSelectSubmitted}
            error={selectError?.message}
            helperText={helperText}
          />
        </FormHelperText>
      </>
    </StyledLabeled>
  );
};

const PREFIX = 'RaFileInput';

const StyledLabeled = styled(Labeled, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  width: '100%',
  [`& .${FileInputClasses.dropZone}`]: {
    background: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    fontFamily: theme.typography.fontFamily,
    cursor: 'pointer',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.getContrastText(theme.palette.background.default),
  },
  [`& .${FileInputClasses.preview}`]: {},
  [`& .${FileInputClasses.removeButton}`]: {},
}));

export default S3ImageInput;
