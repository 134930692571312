import { BoardGameStatus } from '../../utils/enum';
import { blue, green, grey, orange } from '@mui/material/colors';

const boardGameStatusChoices = [
  {
    id: BoardGameStatus.WISHLIST,
    name: 'resource.board-games.field.status.wishlist',
    color: orange[500],
  },
  {
    id: BoardGameStatus.PREORDERED,
    name: 'resource.board-games.field.status.preordered',
    color: blue[500],
  },
  {
    id: BoardGameStatus.OWNED,
    name: 'resource.board-games.field.status.owned',
    color: green[500],
  },
  {
    id: BoardGameStatus.PLAYED,
    name: 'resource.board-games.field.status.played',
    color: grey[500],
  },
];

export default boardGameStatusChoices;
