import * as React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  FormTab,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
  useTranslate,
} from 'react-admin';
import boardGameLanguageChoices from '../../../choices/board-game/language.choices';
import boardGameStatusChoices from '../../../choices/board-game/status.choices';
import { BoardGameStatus, Language } from '../../../utils/enum';
import CustomRichTextInput from '../../components/common/CustomRichTextInput';
import S3ImageInput from '../../components/common/S3ImageInput';
import TagsReferenceArrayInput from '../../components/tags/TagsReferenceArrayInput';
import { Grid } from '@mui/material';

const BoardGameCreate = () => {
  const translate = useTranslate();

  return (
    <Create
      title={translate('ra.page.create', {
        name: translate('resource.board-games.name', {
          smart_count: 1,
        }),
      })}
    >
      <TabbedForm>
        <FormTab
          label="resource.board-games.tab.general"
          sx={{ maxWidth: '20em' }}
        >
          <TextInput
            source="nameFr"
            label="resource.board-games.field.nameFr"
            fullWidth
            validate={required()}
          />
          <TextInput
            source="nameEn"
            label="resource.board-games.field.nameEn"
            fullWidth
            validate={required()}
          />
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <TextInput
                source="officialNumberOfPlayers"
                label="resource.board-games.field.officialNumberOfPlayers"
                defaultValue="2,3,4"
                fullWidth
                validate={required()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                source="myNumberOfPlayers"
                label="resource.board-games.field.myNumberOfPlayers"
                defaultValue="2,3,4"
                fullWidth
                validate={required()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <NumberInput
                source="officialPlayingTime"
                label="resource.board-games.field.officialPlayingTime"
                min={5}
                step={5}
                defaultValue={45}
                fullWidth
                validate={required()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <NumberInput
                source="myPlayingTime"
                label="resource.board-games.field.myPlayingTime"
                min={5}
                step={5}
                defaultValue={45}
                fullWidth
                validate={required()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <NumberInput
                source="officialMinimumAge"
                label="resource.board-games.field.officialMinimumAge"
                min={3}
                step={1}
                defaultValue={10}
                fullWidth
                validate={required()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <NumberInput
                source="myMinimumAge"
                label="resource.board-games.field.myMinimumAge"
                min={3}
                step={1}
                defaultValue={10}
                fullWidth
                validate={required()}
              />
            </Grid>
          </Grid>
          <NumberInput
            source="complexity"
            label="resource.board-games.field.complexity"
            min={1}
            max={100}
            step={1}
            defaultValue={40}
            fullWidth
            validate={required()}
          />
        </FormTab>
        <FormTab
          label="resource.board-games.tab.ratings"
          sx={{ maxWidth: '20em' }}
        >
          <NumberInput
            source="overall"
            label="resource.board-games.field.overall"
            min={1}
            max={100}
            step={1}
            defaultValue={75}
            fullWidth
            validate={required()}
          />
          <NumberInput
            source="design"
            label="resource.board-games.field.design"
            min={1}
            max={100}
            step={1}
            defaultValue={75}
            fullWidth
            validate={required()}
          />
          <NumberInput
            source="setup"
            label="resource.board-games.field.setup"
            min={1}
            max={100}
            step={1}
            defaultValue={75}
            fullWidth
            validate={required()}
          />
          <NumberInput
            source="luck"
            label="resource.board-games.field.luck"
            min={0}
            max={20}
            step={1}
            defaultValue={15}
            fullWidth
            validate={required()}
          />
          <NumberInput
            source="reflection"
            label="resource.board-games.field.reflection"
            min={0}
            max={20}
            step={1}
            defaultValue={15}
            fullWidth
            validate={required()}
          />
          <NumberInput
            source="replayability"
            label="resource.board-games.field.replayability"
            min={0}
            max={20}
            step={1}
            defaultValue={15}
            fullWidth
            validate={required()}
          />
          <NumberInput
            source="fun"
            label="resource.board-games.field.fun"
            min={0}
            max={20}
            step={1}
            defaultValue={15}
            fullWidth
            validate={required()}
          />
          <NumberInput
            source="interactivity"
            label="resource.board-games.field.interactivity"
            min={0}
            max={20}
            step={1}
            defaultValue={15}
            fullWidth
            validate={required()}
          />
        </FormTab>
        <FormTab label="resource.board-games.tab.images">
          <S3ImageInput
            source="box"
            label="resource.board-games.field.box"
            isRequired
            validate={required()}
          />
          <S3ImageInput
            source="smallBox"
            label="resource.board-games.field.smallBox"
            isRequired
            validate={required()}
          />
          <S3ImageInput
            source="thumbnail"
            label="resource.board-games.field.thumbnail"
            isRequired
            validate={required()}
          />
          <S3ImageInput
            source="pictures"
            label="resource.board-games.field.pictures"
            multiple
          />
        </FormTab>
        <FormTab label="resource.board-games.tab.texts">
          <CustomRichTextInput
            source="descriptionFr"
            label="resource.board-games.field.descriptionFr"
          />
          <CustomRichTextInput
            source="descriptionEn"
            label="resource.board-games.field.descriptionEn"
          />
          <CustomRichTextInput
            source="opinionFr"
            label="resource.board-games.field.opinionFr"
          />
          <CustomRichTextInput
            source="opinionEn"
            label="resource.board-games.field.opinionEn"
          />
        </FormTab>
        <FormTab label="resource.board-games.tab.tags">
          <TagsReferenceArrayInput degree="primary" />
          <TagsReferenceArrayInput degree="secondary" />
          <TagsReferenceArrayInput degree="tertiary" />
        </FormTab>
        <FormTab
          label="resource.board-games.tab.misc"
          sx={{ maxWidth: '20em' }}
        >
          <SelectInput
            source="status"
            label="resource.board-games.field.status.name"
            choices={boardGameStatusChoices}
            defaultValue={BoardGameStatus.OWNED}
            fullWidth
            isRequired
            validate={required()}
          />
          <SelectInput
            source="language"
            label="resource.board-games.field.language.name"
            choices={boardGameLanguageChoices}
            defaultValue={Language.FRENCH}
            fullWidth
            isRequired
            validate={required()}
          />
          <BooleanInput
            source="pledged"
            label="resource.board-games.field.pledged"
          />
          <ReferenceInput
            source="baseGame.id"
            reference="board-games"
            sort={{ field: 'nameFr', order: 'ASC' }}
          >
            <AutocompleteInput
              label="resource.board-games.field.baseGame"
              optionText="nameFr"
              fullWidth
            />
          </ReferenceInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default BoardGameCreate;
