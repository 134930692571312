import * as React from 'react';
import { AutocompleteArrayInput, ReferenceArrayInput } from 'react-admin';

interface Props {
  degree: 'primary' | 'secondary' | 'tertiary';
}

const TagsReferenceArrayInput: React.FC<Props> = ({ degree }) => (
  <ReferenceArrayInput
    source={`${degree}TagsIds`}
    reference="tags"
    sort={{ field: 'nameFr', order: 'ASC' }}
  >
    <AutocompleteArrayInput
      label={`resource.board-games.field.${degree}Tags`}
      optionText="nameFr"
      fullWidth
    />
  </ReferenceArrayInput>
);

export default TagsReferenceArrayInput;
