import {
  CreateParams,
  CreateResult,
  fetchUtils,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { stringify } from 'query-string';
import jsonServerProvider from 'ra-data-json-server';
import {
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetOneParams,
  GetOneResult,
  RaRecord,
} from 'react-admin';

const apiUrl = process.env.REACT_APP_SERVER_HOST || '';

const httpClient = (
  url: string,
  options: any = {},
): Promise<{
  status: number;
  headers: Headers;
  body: string;
  json: any;
}> => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  const authToken = `Bearer ${localStorage.getItem('accessToken')}`;
  options.headers.set('Authorization', authToken);

  return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(apiUrl, httpClient);

// Generates the query parameters from params
const generateQueryParams = (
  params: GetListParams | GetManyReferenceParams,
): {
  page: number;
  perPage: number;
  sortField: string;
  sortOrder: string;
  [key: string]: any;
} => {
  const { page, perPage } = params.pagination;
  const { field, order } = params.sort;
  const query: any = {
    page,
    perPage,
    sortField: field,
    sortOrder: order,
  };
  if (params.filter) {
    for (const [key, value] of Object.entries(params.filter)) {
      query[`filters[${key}]`] = value;
    }
  }

  return query;
};

const customDataProvider = {
  ...dataProvider,

  // Customizes the list options and filters query params sent to the server
  getList: <RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: GetListParams,
  ): Promise<GetListResult<RecordType>> => {
    const query = generateQueryParams(params);

    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`).then(
      ({ json }) => json,
    );
  },

  // Customizes the GET profile route as React-Admin rejects if id is not the same as the one passed in params
  // Customizes the GET boarg-games route to get list of tags ids by degree
  getOne: <RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: GetOneParams,
  ): Promise<GetOneResult<RecordType>> =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => {
      if (resource === 'users' && params.id === 'me') {
        json.id = 'me';
      } else if (resource === 'board-games') {
        json.primaryTagsIds = json.tagsRelations
          .filter((tagRelation: TagRelationType) => tagRelation.degree === 1)
          .map((tagRelation: TagRelationType) => tagRelation.tagId);
        json.secondaryTagsIds = json.tagsRelations
          .filter((tagRelation: TagRelationType) => tagRelation.degree === 2)
          .map((tagRelation: TagRelationType) => tagRelation.tagId);
        json.tertiaryTagsIds = json.tagsRelations
          .filter((tagRelation: TagRelationType) => tagRelation.degree === 3)
          .map((tagRelation: TagRelationType) => tagRelation.tagId);
      }
      return { data: json };
    }),

  // Customizes the POST boarg-games route to transform number of players into number array
  // and baseGame.id into baseGameId
  create: <RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: CreateParams,
  ): Promise<CreateResult<RecordType>> => {
    if (resource === 'board-games') {
      params.data = adaptBoardGamePayload(params.data);
    }

    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },

  // Customizes the PUT boarg-games route to transform number of players into number array
  // and baseGame.id into baseGameId
  update: <RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: UpdateParams,
  ): Promise<UpdateResult<RecordType>> => {
    if (resource === 'board-games') {
      params.data = adaptBoardGamePayload(params.data);
    }

    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  // Customizes the delete routes as React-Admin rejects if payload is not returned in response body
  delete: <RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: DeleteParams,
  ): Promise<DeleteResult<RecordType>> =>
    dataProvider.delete(resource, params).then(() => {
      return { data: params.previousData as RecordType };
    }),

  // Customizes the delete many routes as React-Admin rejects if identifiers are not returned in response body
  deleteMany: (
    resource: string,
    params: DeleteManyParams,
  ): Promise<DeleteManyResult> =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'DELETE',
        }),
      ),
    ).then(() => {
      return { data: params.ids };
    }),

  getManyReference: <RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<RecordType>> => {
    const query = generateQueryParams(params);

    // Puts target in filters object with its value as an array
    // { filters: { [params.target]: [params.id] } }
    query[`filters[${params.target}]`] = params.id;

    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`).then(
      ({ json }) => json,
    );
  },

  // Custom function to get all records of a resource (not paginated)
  getAll: <RecordType extends RaRecord = RaRecord>(
    resource: string,
  ): Promise<RecordType[]> => {
    return httpClient(`${apiUrl}/${resource}`).then(({ json }) => json);
  },

  // Custom function to upload a file to a bucket
  uploadFileToS3: (file: File, folder?: string): Promise<any> => {
    const formData = new FormData();
    formData.append('file', file);
    if (folder) {
      formData.append('folder', folder);
    }

    return httpClient(`${apiUrl}/files`, {
      method: 'POST',
      body: formData,
    }).then(({ json }) => json);
  },

  // Custom function to delete a S3 object from a bucket
  deleteS3Object: (fileUrl: string): Promise<void> => {
    return httpClient(`${apiUrl}/files?url=${fileUrl}`, {
      method: 'DELETE',
    }).then(({ json }) => json);
  },

  request: (endpoint: string, method = 'GET'): Promise<any> => {
    return httpClient(`${apiUrl}${endpoint}`, {
      method,
    }).then(({ json }) => json);
  },
};

const adaptBoardGamePayload = (payload: Partial<any>): Partial<any> => {
  if (typeof payload.officialNumberOfPlayers === 'string') {
    payload.officialNumberOfPlayers = payload.officialNumberOfPlayers
      .split(',')
      .map((value: string) => parseInt(value, 10));
  }
  if (typeof payload.myNumberOfPlayers === 'string') {
    payload.myNumberOfPlayers = payload.myNumberOfPlayers
      .split(',')
      .map((value: string) => parseInt(value, 10));
  }
  if (!!payload.baseGame?.id) {
    payload.baseGameId = payload.baseGame.id;
    delete payload.baseGame;
  }

  return payload;
};

export default customDataProvider;
