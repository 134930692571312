import * as React from 'react';
import {
  DeleteWithConfirmButton,
  Edit,
  email,
  FieldProps,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  ToolbarProps,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import GravatarField from '../../components/users/GravatarField';
import CustomLink from '../../components/common/CustomLink';
import CustomToolbar from '../../components/common/CustomToolbar';
import { useFormState } from 'react-hook-form';

const EditGravatar: React.FC<FieldProps> = (props) => {
  const record = useRecordContext(props);
  const translate = useTranslate();

  return record ? (
    <Box display="flex" alignItems="center">
      <GravatarField record={record} />
      <Box sx={{ ml: 2 }}>
        <Typography sx={{ mb: 1 }}>
          {translate('resource.profile.action.edit.avatar')}
        </Typography>
        <CustomLink href="https://en.gravatar.com/" target="blank">
          Gravatar
        </CustomLink>
      </Box>
    </Box>
  ) : null;
};

const ProfileToolbar: React.FC<Omit<ToolbarProps, 'width'>> = (props) => {
  const { isDirty, isValid } = useFormState();

  return (
    <CustomToolbar>
      <SaveButton disabled={!isDirty || !isValid} />
      <DeleteWithConfirmButton
        label="resource.profile.action.delete.button"
        confirmTitle="resource.profile.action.delete.title"
        confirmContent="resource.profile.action.delete.message"
        size="medium"
        mutationMode="undoable"
        onClick={() => localStorage.removeItem('accessToken')}
        redirect="/login"
      />
    </CustomToolbar>
  );
};

const ProfileEdit = () => {
  return (
    <Edit
      resource="users"
      id="me"
      title="resource.profile.name"
      redirect={false}
    >
      <SimpleForm toolbar={<ProfileToolbar />} sx={{ maxWidth: '20em' }}>
        <TextInput
          source="firstName"
          label="resource.users.field.firstName"
          fullWidth
          isRequired
          validate={required()}
        />
        <TextInput
          source="lastName"
          label="resource.users.field.lastName"
          fullWidth
          isRequired
          validate={required()}
        />
        <TextInput
          source="email"
          label="resource.users.field.email"
          fullWidth
          isRequired
          validate={email()}
        />
        <EditGravatar />
      </SimpleForm>
    </Edit>
  );
};

export default ProfileEdit;
