import * as React from 'react';
import { Title, useAuthenticated } from 'react-admin';
import { Box } from '@mui/material';
import ChartCard from '../components/dashboard/ChartCard';
import BoardGamesCountChart from '../components/dashboard/BoardGamesCountChart';
import BoardGamesRatesCountChart from '../components/dashboard/BoardGamesRatesCountChart';

const Dashboard: React.FC = () => {
  useAuthenticated();

  return (
    <Box sx={{ mt: 2 }}>
      <Title title="ra.page.dashboard" />
      <ChartCard label="screen.dashboard.charts.boardGamesTypes.title">
        <BoardGamesCountChart />
      </ChartCard>
      <ChartCard
        label="screen.dashboard.charts.boardGamesRatesTypes.title"
        labelSuffix="resource.board-games.field.overall"
      >
        <BoardGamesRatesCountChart type="overall" />
      </ChartCard>
      <ChartCard
        label="screen.dashboard.charts.boardGamesRatesTypes.title"
        labelSuffix="resource.board-games.field.design"
      >
        <BoardGamesRatesCountChart type="design" />
      </ChartCard>
      <ChartCard
        label="screen.dashboard.charts.boardGamesRatesTypes.title"
        labelSuffix="resource.board-games.field.setup"
      >
        <BoardGamesRatesCountChart type="setup" />
      </ChartCard>
      <ChartCard
        label="screen.dashboard.charts.boardGamesRatesTypes.title"
        labelSuffix="resource.board-games.field.luck"
      >
        <BoardGamesRatesCountChart type="luck" />
      </ChartCard>
      <ChartCard
        label="screen.dashboard.charts.boardGamesRatesTypes.title"
        labelSuffix="resource.board-games.field.reflection"
      >
        <BoardGamesRatesCountChart type="reflection" />
      </ChartCard>
      <ChartCard
        label="screen.dashboard.charts.boardGamesRatesTypes.title"
        labelSuffix="resource.board-games.field.replayability"
      >
        <BoardGamesRatesCountChart type="replayability" />
      </ChartCard>
      <ChartCard
        label="screen.dashboard.charts.boardGamesRatesTypes.title"
        labelSuffix="resource.board-games.field.fun"
      >
        <BoardGamesRatesCountChart type="fun" />
      </ChartCard>
      <ChartCard
        label="screen.dashboard.charts.boardGamesRatesTypes.title"
        labelSuffix="resource.board-games.field.interactivity"
      >
        <BoardGamesRatesCountChart type="interactivity" />
      </ChartCard>
    </Box>
  );
};

export default Dashboard;
