import { TranslationMessages } from 'react-admin';
import frenchMessages from 'ra-language-french';

const customFrenchhMessages: TranslationMessages = {
  ...frenchMessages,
  ra: {
    ...frenchMessages.ra,
    auth: {
      ...frenchMessages.ra.auth,
      email: 'Email',
    },
  },
  month: {
    1: 'Janvier',
    2: 'Février',
    3: 'Mars',
    4: 'Avril',
    5: 'Mai',
    6: 'Juin',
    7: 'Juillet',
    8: 'Août',
    9: 'Septembre',
    10: 'Octobre',
    11: 'Novembre',
    12: 'Décembre',
  },
  component: {
    s3ImageInput: {
      action: {
        uploading: 'Chargement en cours...',
        filesNotSupported: '%{filesCount} fichier(s) non supporté(s)',
        uploadFileError: "Échec de l'importation du fichier",
        deleteFileError: "Échec de la suppression de l'ancien fichier",
        selectRequiredError: 'Vous devez sélectionner au moins 1 image',
      },
    },
    richText: {
      imageButtons: {
        label: 'Image',
        dialogTitle: "URL de l'image",
      },
      colorButtons: {
        colorLabel: 'Couleur',
        highlightLabel: 'Fond',
      },
    },
  },
  screen: {
    dashboard: {
      charts: {
        boardGamesTypes: {
          title: 'Types de jeux',
          baseGames: 'Jeux de base',
          expansions: 'Extensions',
        },
        boardGamesRatesTypes: {
          title: 'Jeux par note : ',
          games: 'Jeux',
        },
      },
    },
    configurations: {
      name: 'Préférences',
      field: {
        language: 'Langue',
        theme: {
          name: 'Theme',
          light: 'Clair',
          dark: 'Obscur',
        },
      },
    },
  },
  resource: {
    global: {
      field: {
        title: 'Title',
        subtitle: 'Sous-titre',
        date: 'Date',
        name: 'Nom',
        nameFr: 'Nom 🇫🇷',
        nameEn: 'Nom 🇬🇧',
        value: 'Valeur',
        thumbnail: 'Vignette',
      },
      action: {
        copy: 'Copier',
        textCopied: '%{textName} copié dans le presse-papier avec succés',
        copyError: 'Échec de la copie de %{textName} dans le presse-papier',
      },
    },
    common: {
      field: {
        id: 'id',
        slug: 'Slug',
        createdAt: 'Créé le',
        updatedAt: 'Modifié le',
      },
    },
    administration: {
      name: 'Administration',
    },
    profile: {
      name: 'Profil',
      action: {
        edit: {
          avatar: 'Vous pouvez le personnaliser sur',
        },
        delete: {
          button: 'Supprimer mon compte',
          title: 'Êtes-vous sûr(e) de vouloir supprimer votre compte ?',
          message: 'Toutes les créations liées seront également supprimées.',
        },
      },
    },
    users: {
      name: 'Utilisateur |||| Utilisateurs',
      field: {
        firstName: 'Prénom',
        lastName: 'Nom',
        fullName: 'Nom complet',
        email: 'Email',
        role: {
          name: 'Rôle',
          admin: 'Admin',
          publisher: 'Éditeur',
          editor: 'Rédacteur',
        },
        isActivated: 'Activé',
        unactivated: 'Inactivé',
      },
      action: {
        activate: 'Activer',
        userActivated: 'Utilisateur activé',
      },
    },
    'api-keys': {
      name: 'Clef API |||| Clefs API',
    },
    'board-games': {
      name: 'Jeu |||| Jeux',
      tab: {
        general: 'Général',
        ratings: 'Notes',
        images: 'Images',
        texts: 'Textes',
        tags: 'Labels',
        misc: 'Autre',
      },
      field: {
        nameFr: 'Nom 🇫🇷',
        nameEn: 'Nom 🇬🇧',
        descriptionFr: 'Description 🇫🇷',
        descriptionEn: 'Description 🇬🇧',
        opinionFr: 'Opinion 🇫🇷',
        opinionEn: 'Opinion 🇬🇧',
        officialNumberOfPlayers: 'Nombre de joueurs officiel',
        myNumberOfPlayers: 'Mon nombre de joueurs',
        officialPlayingTime: 'Temps de jeu officiel',
        myPlayingTime: 'Mon temps de jeu',
        officialMinimumAge: 'Âge minimum officiel',
        myMinimumAge: 'Mon âge minimum',
        complexity: 'Complexité',
        overall: 'Global',
        design: 'Design',
        setup: 'Installation',
        luck: 'Chance',
        reflection: 'Réflexion',
        replayability: 'Rejouabilité',
        fun: 'Fun',
        interactivity: 'Interactivité',
        box: 'Boîte [max-width: 800px or max-height: 1000px]',
        smallBox: 'Boîte (petite) [width: 350px]',
        thumbnail: 'Thumbnail [300x250]',
        pictures: 'Photos [width: 800px]',
        status: {
          name: 'Statut',
          wishlist: 'Souhaité',
          preordered: 'Commandé',
          owned: 'Possédé',
          played: 'Joué',
        },
        language: {
          name: 'Langage',
          french: 'Français',
          english: 'Anglais',
        },
        pledged: 'Financé',
        tags: 'Labels',
        primaryTags: 'Labels principaux',
        secondaryTags: 'Labels secondaires',
        tertiaryTags: 'Autres labels',
        baseGame: 'Jeu de base',
        isExpansion: 'Extension',
      },
    },
    tags: {
      name: 'Label |||| Labels',
      field: {
        existing: 'Tags existants',
        hasRelations: 'Jeu(x) lié(s)',
      },
      action: {
        fetchExistingError: 'Échec de la récupération des tags existants',
      },
    },
  },
};

export default customFrenchhMessages;
